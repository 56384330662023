import { createStore } from "redux";
import React from "react";

export const updateService = (service) => ({
  type: "UPDATE_SERVICE",
  service,
});

export const updateScrollbarScale = (scale) => ({
  type: "UPDATE_SCROLLBAR_SCALE",
  scale,
});

export const toggleNav = (visible) => ({
  type: "TOGGLE_NAV",
  visible,
});

export const setFullscreen = (index) => ({
  type: "SET_FULLSCREEN",
  index,
});

const defaultState = {
  loading: true,
  nightMode: false,
  service: 0,
  scrollbarScale: 100,
  navOpen: false,
  fullscreen: null,
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case "UPDATE_SERVICE":
      return Object.assign({}, state, { service: action.service });

    case "UPDATE_SCROLLBAR_SCALE":
      return Object.assign({}, state, { scrollbarScale: action.scale });

    case "TOGGLE_NAV":
      return Object.assign({}, state, { navOpen: action.visible });

    case "SET_FULLSCREEN":
      if (action.index !== null) window.history.pushState({}, "");

      return Object.assign({}, state, { fullscreen: action.index });

    default:
      return state;
  }
};

export default createStore(reducer);
