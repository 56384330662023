import React, { useState, useEffect } from "react";
import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import { connect } from "react-redux";
import { navigate, Link } from "gatsby";
import { motion, AnimatePresence, useViewportScroll } from "framer-motion";
import { useWheel } from "react-use-gesture";
import SEO from "./seo";

import {
  updateService,
  updateScrollbarScale,
  toggleNav,
  setFullscreen,
} from "./store";

import "../index.less";

const sanityClient = createClient({
  projectId: "mk2mb5rx",
  dataset: "production",
  useCdn: true,
  apiVersion: "2021-10-21",
});

const imageBuilder = imageUrlBuilder(sanityClient);
const urlFor = (source) => imageBuilder.image(source);

const pages = {
  fr: ["/", "/news", "/about", "/services", "/realisations", "/contact"],
  en: [
    "/en",
    "/en/news",
    "/en/about",
    "/en/services",
    "/en/realisations",
    "/en/contact",
  ],
};

const pageNames = {
  fr: [
    "Accueil",
    "Actualités",
    "À propos",
    "Services",
    "Réalisations",
    "Contact",
  ],
  en: ["Home", "News", "About", "Services", "Achievements", "Contact"],
};

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    filter: "blur(0px)",
  },
  blur: {
    filter: "blur(5px)",
  },
  exit: {
    opacity: 0,
    filter: "blur(5px)",
  },
};

const blurVariants = {
  initial: {
    filter: "blur(0px)",
  },
  blur: {
    filter: "blur(5px)",
  },
};

const navVariants = {
  initial: {
    x: "100%",
  },
  visible: {
    x: 0,
  },
};

const Layout = ({
  navOpen,
  fullscreen,
  service,
  dispatch,
  children,
  location,
}) => {
  const [bgImgs, setBGImgs] = useState([]);
  const [realisations, setRealisations] = useState(null);
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const [lang, setLang] = useState("fr");
  const [page, setPage] = useState(0);

  const bind = useWheel(
    ({ event, first, last }) => {
      if (first && !scrollDisabled && fullscreen === null) {
        let pathname = location.pathname;
        if (pathname !== "/") pathname = pathname.replace(/\/$/, "");

        if (pathname.indexOf("/news/") === 0 && pathname !== "/news/") return;
        if (pathname.indexOf("/en/news/") === 0 && pathname !== "/en/news/")
          return;

        if (pathname === "/services" || pathname === "/en/services") {
          if (event.deltaY > 0) {
            let s = service + 1;

            if (s === 3)
              navigate(
                pages[lang][pages.fr.findIndex((i) => i === "/services") + 1]
              );
            else dispatch(updateService(s));

            setTimeout(() => setScrollDisabled(false), 1000);
          } else if (event.deltaY < 0) {
            let s = service - 1;

            if (s === -1)
              navigate(
                pages[lang][pages.fr.findIndex((i) => i === "/services") - 1]
              );
            else dispatch(updateService(s));

            setTimeout(() => setScrollDisabled(false), 1000);
          }
        } else {
          if (
            (event.deltaY > 0 && scrollYProgress.current === 1) ||
            event.deltaY > 0
          ) {
            let index = pages[lang].findIndex((i) => i === pathname);
            index += 1;

            if (index === 1) dispatch(updateService(0));

            if (index < pages[lang].length) {
              dispatch(updateScrollbarScale(100));
              navigate(pages[lang][index]);
            }

            setTimeout(() => setScrollDisabled(false), 1000);
          } else if (event.deltaY < 0 && scrollYProgress.current === 1) {
            let index = pages[lang].findIndex((i) => i === pathname);
            index -= 1;

            if (index === 1) dispatch(updateService(2));

            if (index >= 0) {
              dispatch(updateScrollbarScale(100));
              navigate(pages[lang][index]);
            }

            setTimeout(() => setScrollDisabled(false), 1000);
          }
        }
      }

      if (last) setTimeout(() => setScrollDisabled(false), 300);
    },
    {
      domTarget:
        typeof document === "undefined"
          ? null
          : document.getElementById("___gatsby"),
    }
  );

  useEffect(() => {
    let pathname = location.pathname;
    let language = "fr";

    if (pathname.startsWith("/en")) language = "en";

    if (pathname !== "/") pathname = pathname.replace(/\/$/, "");

    setPage(pages[language].findIndex((i) => i === pathname));
    setLang(language);
    bind();

    let historyPopListener = window.addEventListener("popstate", () => {
      dispatch(setFullscreen(null));
    });

    const fetchRealisations = async () => {
      const req = await fetch(
        'https://mk2mb5rx.api.sanity.io/v2021-10-21/data/query/production?query=*[_type == "realisation"]'
      );
      const res = await req.json();
      setRealisations(res.result);
    };

    fetchRealisations();

    const fetchBGImgs = async () => {
      const req = await fetch(
        'https://mk2mb5rx.api.sanity.io/v2021-10-21/data/query/production?query=*[_type == "settings"]'
      );
      const res = await req.json();
      setBGImgs([
        urlFor(res.result[0].home_image).width(1600).url(),
        null,
        null,
        urlFor(res.result[0].services_image).width(1600).url(),
        urlFor(res.result[0].image_realisations).width(1600).url(),
        urlFor(res.result[0].image_contact).width(1600).url(),
      ]);
    };

    fetchBGImgs();

    return () => {
      window.removeEventListener("popstate", historyPopListener);
    };
  }, [setPage, setLang, location, bind]);

  return (
    <div className="layout" onClick={() => dispatch(toggleNav(false))}>
      {bgImgs[page] ? (
        <motion.div
          initial={{
            filter: "blur(0px)",
          }}
          animate={{
            filter: navOpen ? "blur(5px)" : "blur(0px)",
          }}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundImage: `url('${bgImgs[page]}')`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
      ) : null}

      <motion.header
        variants={blurVariants}
        initial="initial"
        animate={navOpen ? "blur" : "initial"}
      >
        <a href="/">
          <img src="/logo.svg" alt="Concilio" />
        </a>

        <div>
          <div className="languages">
            <Link to={pages.fr[page]} className={lang === "fr" ? "active" : ""}>
              FR
            </Link>{" "}
            |{" "}
            <Link to={pages.en[page]} className={lang === "en" ? "active" : ""}>
              EN
            </Link>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
              dispatch(toggleNav(true));
            }}
          >
            <span className="nav-icon" />
            MENU
          </div>
        </div>
      </motion.header>

      <motion.div
        className="nav"
        variants={navVariants}
        initial="initial"
        animate={navOpen ? "visible" : "initial"}
        transition={{
          duration: 0.2,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <span className="close" onClick={() => dispatch(toggleNav(false))}>
          X
        </span>

        <ul className="menu">
          {pages[lang].map((p, index) => (
            <li
              key={index}
              className={page === index ? "active" : ""}
              onClick={() => dispatch(toggleNav(false))}
            >
              <Link to={`${p}`}>{pageNames[lang][index]}</Link>
            </li>
          ))}
        </ul>
      </motion.div>

      {pageNames[lang][page] ? (
        <motion.div
          className="nav-viewer"
          variants={blurVariants}
          initial="initial"
          animate={navOpen ? "blur" : "initial"}
        >
          <div className="nav-label">{pageNames[lang][page]}</div>
          <div className="nav-bar" style={{ top: `${page * 16.666}%` }} />
        </motion.div>
      ) : null}

      <AnimatePresence mode="wait">
        <motion.main
          key={location.pathname}
          variants={variants}
          initial="initial"
          animate={navOpen ? "blur" : "enter"}
          exit="exit"
          id="main"
        >
          {children}
        </motion.main>
      </AnimatePresence>

      {page === 0 && (
        <div
          className="scroll-down"
          style={{
            position: "absolute",
            bottom: "15vh",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <small style={{ color: "rgba(255, 255, 255, 0.5)" }}>
            scroll down
          </small>
          <div
            style={{
              position: "absolute",
              left: "50%",
              width: 1,
              height: 50,
              marginTop: 20,
              background: "rgba(255, 255, 255, 0.3)",
            }}
          />
        </div>
      )}

      <motion.div
        initial={{
          opacity: 0,
          scale: 0,
          overflow: "hidden",
        }}
        animate={{
          opacity: fullscreen === null ? 0 : 1,
          scale: fullscreen === null ? 0 : 1,
        }}
        transition={{
          duration: 0.3,
        }}
        className="fullscreen-realisations"
      >
        {fullscreen !== null && realisations !== null && (
          <>
            <div
              className="image"
              style={{
                backgroundImage: `url(${urlFor(realisations[fullscreen].image)
                  .width(1600)
                  .url()})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
            <div className="text">
              <h3 style={{ fontSize: 32 }}>
                {lang === "fr"
                  ? realisations[fullscreen].name_fr
                  : realisations[fullscreen].name_en}
              </h3>
              <PortableText
                value={
                  lang === "fr"
                    ? realisations[fullscreen].content_fr
                    : realisations[fullscreen].content_en
                }
              />
            </div>
          </>
        )}
        <div className="close" onClick={() => dispatch(setFullscreen(null))}>
          X
        </div>
      </motion.div>
    </div>
  );
};

export default connect((state) => ({
  navOpen: state.navOpen,
  fullscreen: state.fullscreen,
  service: state.service,
}))(Layout);
